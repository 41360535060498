<template>
  <div class="row">
    <loading-overlay
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
  />
    <PageMetadata />
    <div id="content" class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-heading style_heading_style" v-if="job.id > 0">
          <h1 class="pb-0 mb-4 px-0">{{ job.title }}</h1>
        </div>
        <div class="panel-body style_body_style">
          <div class="row">
            <form
              action=""
              method="post"
              enctype="multipart/form-data"
              class="form-horizontal"
              id="apply_form"
            >
              <div class="panel-heading" id="referral_info">
                <h4 class="mb-4 font-weight-bold">Referral Information</h4>
                <div class="form-group mb-4">
                  <div class="row">
                    <label class="col-sm-2 col-md-5 col-xl-3 control-label"
                      >Did someone refer you to this job?</label
                    >
                    <div class="col-sm-9 col-md-7 col-xl-9">
                      <input
                        type="radio"
                        v-model="
                          salesInformation.referralInformation.referred
                        "
                        class="any_referal_info"
                        value="Yes"
                      />&nbsp;Yes
                      <input
                        type="radio"
                        v-model="
                          salesInformation.referralInformation.referred
                        "
                        class="any_referal_info"
                        value="No"
                      />&nbsp;No
                    </div>
                  </div>
                </div>
                <div
                  v-if="salesInformation.referralInformation.referred == 'Yes'"
                  class="pt-1 table_1 mb-0"
                  id="referral_info_id"
                >
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.referralInformation.firstName
                      "
                      placeholder="First Name"
                    />
                    <label class="control-label"
                      >First Name</label
                    >
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.referralInformation.errors &&
                        salesInformation.referralInformation.errors
                          .firstNameErrorStatus
                      "
                    >
                      {{
                        salesInformation.referralInformation.errors
                          .firstNameErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.referralInformation.lastName
                      "
                      placeholder="Last Name"
                    />
                    <label class="control-label"
                      >Last Name</label
                    >
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.referralInformation.errors &&
                        salesInformation.referralInformation.errors
                          .lastNameErrorStatus
                      "
                    >
                      {{
                        salesInformation.referralInformation.errors
                          .lastNameErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.referralInformation
                          .primaryPhoneNumber
                      "
                      placeholder="Primary Phone Number"
                    />
                    <label class="control-label">Primary Phone Number</label>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.referralInformation
                          .emailAddress
                      "
                      placeholder="Email Address"
                    />
                    <label class="control-label">Email Address</label>
                  </div>
                </div>
              </div>
              <div class="panel-heading" id="reference_info">
                <h4 class="font-weight-bold mb-3">Personal Details</h4>
                <div id="reference_info_id">
                  <!-- <div class="form-group required">
                    <div class="row">
                      <label class="control-label">First Name </label>
                      <div class="col-sm-9">
                        <div class="row">
                          <div class="col-sm-4 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              v-model="
                                salesInformation.personalDetails.firstName
                              "
                              placeholder="First Name"
                            />
                            <div
                              class="error-text"
                              v-if="
                                salesInformation.personalDetails.errors &&
                                salesInformation.personalDetails.errors
                                  .firstNameErrorStatus
                              "
                            >
                              {{
                                salesInformation.personalDetails.errors
                                  .firstNameErrorMessage
                              }}
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <input
                              type="text"
                              class="form-control"
                              v-model="
                                salesInformation.personalDetails
                                  .middleName
                              "
                              placeholder="Middle Name"
                            />
                          </div>
                          <div class="col-sm-4 pr-0">
                            <input
                              type="text"
                              class="form-control"
                              v-model="
                                salesInformation.personalDetails.lastName
                              "
                              placeholder="Last Name"
                            />
                            <div
                              class="error-text"
                              v-if="
                                salesInformation.personalDetails.errors &&
                                salesInformation.personalDetails.errors
                                  .lastNameErrorStatus
                              "
                            >
                              {{
                                salesInformation.personalDetails.errors
                                  .lastNameErrorMessage
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails.firstName
                      "
                      placeholder="First Name"
                    />
                    <label class="control-label">First Name </label>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .firstNameErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .firstNameErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails
                          .middleName
                      "
                      placeholder="Middle Name"
                    />
                    <label class="control-label">Middle Name</label>
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails.lastName
                      "
                      placeholder="Last Name"
                    />
                    <label class="control-label">Last Name</label>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .lastNameErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .lastNameErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating">
                    <textarea
                      v-model="
                        salesInformation.personalDetails.streetAddress
                      "
                      class="form-control"
                      placeholder="Street Address"
                    ></textarea>
                    <label class="control-label"
                      >Street Address</label
                    >
                    <!-- <div class="error-text" v-if="salesInformation.personalDetails.errors && salesInformation.personalDetails.errors.cityStateZipcodeErrorStatus">{{ salesInformation.personalDetails.errors.cityStateZipcodeErrorMessage }}</div> -->
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails
                          .cityStateZipcode
                      "
                      placeholder="City/State/Zip Code"
                    />
                    <label class="control-label"
                      >City/State/Zip Code</label
                    >
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .cityStateZipcodeErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .cityStateZipcodeErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating required"
                    v-if="!salesInformation.id">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails.position
                      "
                      placeholder="Position"
                    />
                    <label class="control-label"
                      >Position</label
                    >
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .positionErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .positionErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating required"
                    v-if="salesInformation.id">
                    <select
                      class="form-select"
                      name="job_location"
                      id="job_location"
                      v-model="job.branch_id"
                      disabled=""
                    >
                      <option value="1">Any Location</option>
                      <option
                        :value="location.id"
                        v-for="(location, key) in branches"
                        :key="key"
                      >
                        {{ location.city }}, {{ location.state }} <span v-if="location.job_name" >({{ location.job_name }})</span>
                      </option>
                    </select>
                    <!-- <label class="control-label" for="job_location">Location</label> -->
                    <div class="error_location"></div>
                  </div>
                  <div class="form-floating " v-if="!salesInformation.id">
                    <select
                      v-model="
                        salesInformation.personalDetails
                          .otherLocations
                      "
                      @change="
                        selectLocations(
                          salesInformation.personalDetails
                            .otherLocations,
                          false
                        )
                      "
                      multiple="true"
                      id="other_location"
                      class="ignore form-control"
                    >
                      <option value="1">Any Location</option>
                      <option
                        :value="location.id"
                        v-for="(location, key) in branches"
                        :key="key"
                      >
                        {{ location.city }}, {{ location.state }} <span v-if="location.job_name" >({{ location.job_name }})</span>
                      </option>
                    </select>
                    <label class="control-label"
                      >Locations (Hold your CTRL key for multiple
                      selections)</label
                    >
                    <div
                      class="text-danger"
                      id="max_selection_error"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .locationMaxErrorStatus
                      "
                    >
                      Max 3 locations are allowed
                    </div>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .locationErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .locationErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating mb-4 pb-1" v-if="salesInformation.id">
                    <select
                      v-model="
                        salesInformation.personalDetails.otherLocations
                      "
                      @change="
                        selectLocations(
                          salesInformation.personalDetails.otherLocations,
                          true
                        )
                      "
                      multiple="true"
                      id="other_location"
                      class="ignore form-control"
                    >
                      <option value="1">Any Location</option>
                      <option
                        class="level-0"
                        :value="location.id"
                        v-for="(location, key) in branches"
                        :key="key"
                      >
                        {{ location.city }}, {{ location.state }} <span v-if="location.job_name" >({{ location.job_name }})</span>
                      </option>
                    </select>
                    <label class="control-label mb-2">Other Locations (Hold your CTRL key for multiple selections)</label>
                    <div
                      class="text-danger"
                      id="max_selection_error"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .otherLocationsMaxErrorStatus
                      "
                    >
                      Max 3 locations are allowed
                    </div>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .otherLocationsErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .otherLocationsErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails
                          .primaryPhoneNumber
                      "
                      placeholder="Primary Phone Number"
                    />
                    <label class="control-label">Primary Phone Number</label>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .primaryPhoneNumberErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .primaryPhoneNumberErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails
                          .secondaryPhoneNumber
                      "
                      placeholder="Secondary Phone Number"
                    />
                    <label class="control-label">Secondary Phone Number</label>
                    <div class="error_contact_alternate"></div>
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.personalDetails.emailAddress
                      "
                      placeholder="Email Address"
                    />
                    <label class="control-label">Email Address</label>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.personalDetails.errors &&
                        salesInformation.personalDetails.errors
                          .emailAddressErrorStatus
                      "
                    >
                      {{
                        salesInformation.personalDetails.errors
                          .emailAddressErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-group mb-4 required">
                    <div class="row">
                      <label class="col-sm-2 col-md-5 col-xl-3 control-label"
                        >Are you at least 18 years of age</label
                      >
                      <div class="col-sm-9 col-md-7 col-xl-9">
                        <input
                          type="radio"
                          name="apply_teen_agers"
                          v-model="
                            salesInformation.personalDetails
                              .is18YearsOfAge
                          "
                          value="Yes"
                          checked=""
                        />&nbsp;Yes
                        <input
                          type="radio"
                          name="apply_teen_agers"
                          v-model="
                            salesInformation.personalDetails
                              .is18YearsOfAge
                          "
                          value="No"
                        />&nbsp;No
                        <div class="error_teen_agers"></div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-4 required">
                    <div class="row">
                      <label class="col-sm-2 col-md-5 col-xl-3 control-label"
                        >If hired, can you provide proof of eligibility to
                        work in the US?</label
                      >
                      <div class="col-sm-9 col-md-7 col-xl-9">
                        <input
                          type="radio"
                          v-model="
                            salesInformation.personalDetails
                              .hasProofOfEligibilityForUS
                          "
                          value="Yes"
                          checked=""
                        />&nbsp;Yes
                        <input
                          type="radio"
                          v-model="
                            salesInformation.personalDetails
                              .hasProofOfEligibilityForUS
                          "
                          name="apply_work_in_us"
                          value="No"
                        />&nbsp;No
                        <div class="error_work_in_us"></div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4 my-md-5"/>
                </div>
              </div>
              <div class="panel-heading" id="workhistory">
                <h4 class="font-weight-bold mb-3">Work history</h4>
                <div
                  :class="`table_${workHistoryIndex + 1}`"
                  id="work_history_id"
                  v-for="(
                    workHistory, workHistoryIndex
                  ) in salesInformation.workHistory"
                  :key="workHistoryIndex"
                >
                  <p class="small fw-bold mb-3">Work history - {{ workHistoryIndex + 1 }}</p>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="workHistory.title"
                      placeholder="Your Title"
                    />
                    <label class="control-label">
                      Your Title
                    </label>
                    <div
                      class="error-text"
                      v-if="
                        workHistory.errors &&
                        workHistory.errors.titleErrorStatus
                      "
                    >
                      {{ workHistory.errors.titleErrorMessage }}
                    </div>
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="workHistory.companyName"
                      placeholder="Company Name"
                    />
                    <label class="control-label"
                      >Company Name</label
                    >
                    <div
                      class="error-text"
                      v-if="
                        workHistory.errors &&
                        workHistory.errors.companyNameErrorStatus
                      "
                    >
                      {{ workHistory.errors.companyNameErrorMessage }}
                    </div>
                  </div>
                  <div class="form-floating required">
                    <input
                      type="text"
                      class="form-control"
                      v-model="workHistory.cityState"
                      placeholder="City/State"
                    />
                    <label class="control-label"
                      >City/State</label
                    >
                    <div
                      class="error-text"
                      v-if="
                        workHistory.errors &&
                        workHistory.errors.cityStateErrorStatus
                      "
                    >
                      {{ workHistory.errors.cityStateErrorMessage }}
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="workHistory.phone"
                      placeholder="Phone Number"
                    />
                    <label class="control-label">
                      Phone Number
                    </label>
                    <div class="error_phone_number_1"></div>
                  </div>
                  <div class="form-group required">
                    <v-date-picker
                      v-model="workHistory.startDate"
                      :first-day-of-week="1"
                      placeholder="Start Date"
                      color="green"
                      :masks="{ input: 'MM-DD-YYYY' }"
                      trim-weeks
                      :available-dates="availableDates"
                    >
                      <template
                        v-slot="{ inputValue, togglePopover }"
                      >
                        <div
                          @click="togglePopover()"
                          class="form-floating input-group flex-nowrap"
                        >
                          <input
                            :value="inputValue"
                            placeholder="Start Date"
                            class="form-control"
                          />
                          <label class="control-label">Start Date</label>
                          <div class="">
                            <span
                              class="input-group-text"
                              id="basic-addon2"
                              ><i class="fa-solid fa-calendar-days"></i
                            ></span>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                    <div
                      class="error-text"
                      v-if="
                        workHistory.errors &&
                        workHistory.errors.startDateErrorStatus
                      "
                    >
                      {{ workHistory.errors.startDateErrorMessage }}
                    </div>
                    <!-- <input type="text" class="form-control" id="apply_start_date_1" v-model="workHistory.startDate" placeholder="Start Date" readonly=""> -->
                  </div>
                  <div class="form-group required">
                    <!-- <input type="text" class="form-control" id="apply_end_date_1" v-model="workHistory.endDate" placeholder="End Date" readonly=""> -->
                    <v-date-picker
                      v-model="workHistory.endDate"
                      placeholder="End Date"
                      :first-day-of-week="1"
                      color="green"
                      :masks="{ input: 'MM-DD-YYYY' }"
                      trim-weeks
                      :available-dates="availableDates"
                    >
                      <template
                        v-slot="{ inputValue, togglePopover }"
                      >
                        <div
                          @click="togglePopover()"
                          class="form-floating input-group flex-nowrap"
                        >
                          <input
                            :value="inputValue"
                            class="form-control"
                            placeholder="End Date"
                          />
                          <label class="control-label"
                            >End Date</label
                          >
                          <div class="">
                            <span
                              class="input-group-text"
                              id="basic-addon2"
                              ><i class="fa-solid fa-calendar-days"></i
                            ></span>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                    <div
                      class="error-text"
                      v-if="
                        workHistory.errors &&
                        workHistory.errors.endDateErrorStatus
                      "
                    >
                      {{ workHistory.errors.endDateErrorMessage }}
                    </div>
                  </div>
                  <div class="form-group mb-4 required">
                    <div class="row">
                      <label class="col-sm-2 col-md-5 col-xl-3 control-label"
                        >Is this your current position?</label
                      >
                      <div class="col-sm-9 col-md-7 col-xl-9">
                        <input
                          type="radio"
                          v-model="workHistory.currentPosition"
                          value="Yes"
                          checked=""
                        />&nbsp;Yes
                        <input
                          type="radio"
                          v-model="workHistory.currentPosition"
                          value="No"
                        />&nbsp;No
                        <div class="error_your_corrent_location_1"></div>
                      </div>
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="workHistory.duties"
                      placeholder="Duties"
                    />
                    <label class="control-label">Duties</label>
                    <div class="error_duties_1"></div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <button
                  type="button"
                  @click="addWorkHistory"
                  class="btn btn-success plus add_field_button"
                >
                  <i class="fa-solid fa-plus-circle"></i>
                </button>
                <button
                  type="button"
                  @click="removeWorkHistory(workHistoryIndex)"
                  id="fullSectionMinus"
                  class="btn btn-danger minus remove_field_button"
                >
                  <i class="fa-solid fa-circle-minus"></i>
                </button>
              </div>
              <hr class="my-4 my-md-5">
              <div class="panel-heading">
                <h4 class="mb-3 font-weight-bold">Education History</h4>
                <div id="education_history_id">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.educationalHistory
                          .institutionName
                      "
                      placeholder="Academic Institution Name"
                    />
                    <label class="control-label"
                      >Academic Institution Name</label
                    >
                    <div class="error_institution_name"></div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.educationalHistory.cityState
                      "
                      placeholder="City/State"
                    />
                    <label class="control-label">
                      City/State
                    </label>
                    <div class="error_city_state"></div>
                  </div>
                  <div class="form-group mb-4">
                    <div class="row">
                      <label class="col-sm-2 col-md-5 col-xl-3 control-label"
                        >Did you graduate?</label
                      >
                      <div class="col-sm-9 col-md-7 col-xl-9">
                        <input
                          type="radio"
                          v-model="
                            salesInformation.educationalHistory.graduate
                          "
                          value="Yes"
                          checked=""
                        />&nbsp;Yes
                        <input
                          type="radio"
                          v-model="
                            salesInformation.educationalHistory.graduate
                          "
                          value="No"
                        />&nbsp;No
                        <div class="error_graduate"></div>
                      </div>
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        salesInformation.educationalHistory.degreeType
                      "
                      placeholder="Degree Type"
                    />
                    <label class="control-label"
                      >Degree Type</label
                    >
                    <div class="error_degree_type"></div>
                  </div>
                </div>
              </div>
              <hr class="my-4 my-md-5">
              <div class="panel-heading" id="professional_reference">
                <h4 class="font-weight-bold mb-3">Professional References</h4>
                <div
                  id="professional-references-id"
                  :class="`table_${referenceIndex + 1} mb-0`"
                  v-for="(
                    referenceList, referenceIndex
                  ) in salesInformation.professionalReferences"
                  :key="referenceIndex"
                >
                  <p class="small fw-bold mb-3">Reference - {{ referenceIndex + 1 }}</p>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="referenceList.firstNameLastName"
                      placeholder="First/Last Name"
                    />
                    <label class="control-label">First/Last Name</label>
                    <div class="error_f_l_name_1"></div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="referenceList.primaryPhoneNumber"
                      placeholder="Primary Phone Number"
                    />
                    <label class="control-label">Primary Phone Number</label>
                    <div class="error_p_phone_1"></div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="referenceList.email"
                      placeholder="Email"
                    />
                    <label class="control-label">Email</label>
                    <div class="error_email_1"></div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="referenceList.title"
                      placeholder="Title"
                    />
                    <label class="control-label">Title</label>
                    <div class="error_title_1"></div>
                  </div>
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      v-model="referenceList.relationship"
                      placeholder="Relationship (Manager, coworker, etc.)"
                    />
                    <label class="control-label">Relationship (Manager, coworker, etc.)</label>
                    <div class="error_relationship_1"></div>
                  </div>
                </div>
                <div class="mb-3">
                  <button
                    type="button"
                    @click="addReference"
                    class="btn btn-success plus add_field_button"
                  >
                    <i class="fa-solid fa-plus-circle"></i>
                  </button>
                  <button
                    type="button"
                    @click="removeReference(referenceIndex)"
                    id="fullSectionMinus"
                    class="btn btn-danger minus remove_field_button2"
                  >
                    <i class="fa-solid fa-circle-minus"></i>
                  </button>
                </div>
              </div>
              <hr class="my-4 my-md-5">
              <div class="panel-heading" id="upload-resume">
                <h4 class="mb-3 font-weight-bold">Upload Resume</h4>
                <div id="upload-resume-id">
                  <div class="form-group mb-0">
                    <label class="control-label mb-2">Attached Resume</label>
                    <div class="row">
                      <div class="col-12 col-md mb-3 mb-md-0">
                        <input
                          type="file"
                          @change="onFileChange"
                          class="form-control"
                          name="myFile"
                          ref="file"
                          id="file"
                          accept=".doc, .docx, .txt, .pdf"
                        />
                        <div class="error_resume"></div>
                      </div>
                      <label class="col-12 col-md-auto">
                        <div class="alert alert-danger px-2 mb-0">
                          Upload only .doc,.docx,.txt,.pdf format
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4 my-md-5">
              <div class="panel-heading" id="other-information">
                <h4 class="mb-4 font-weight-bold">Other Information</h4>
                <div id="other-information-id">
                  <div class="form-floating required">
                    <textarea
                      v-model="
                        salesInformation.otherInformation
                          .howDidYouHearAboutUs
                      "
                      placeholder="How did you hear about us?"
                      class="form-control"
                    ></textarea>
                    <label class="control-label">How did you hear about us?</label>
                    <div
                      class="error-text"
                      v-if="
                        salesInformation.otherInformation.errors &&
                        salesInformation.otherInformation.errors
                          .howDidYouHearAboutUsErrorStatus
                      "
                    >
                      {{
                        salesInformation.otherInformation.errors
                          .howDidYouHearAboutUsErrorMessage
                      }}
                    </div>
                  </div>
                  <div class="form-floating">
                    <textarea
                      v-model="
                        salesInformation.otherInformation.comments
                      "
                      placeholder="Comments"
                      class="form-control"
                    ></textarea>
                    <label class="control-label">Comments</label>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button
                  @click="submitForm"
                  class="btn btn-primary"
                  type="button"
                  id="apply_form_btn"
                >
                  Submit
                </button>
                <a href="#" class="btn btn-primary">Back</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PageMetadata } from "@/components";
import { misc } from "@/mixins";
import axios from "axios";
export default {
  name: "ApplyJobs",
  mixins: [misc],
  components: { PageMetadata },
  data() {
    return {
      isLoading: false,
      loader: "bars",
      availableDates: [],
      job: {},
      files: [],
      branches: [],
      salesInformation: {
        id: 0,
        position: "",
        location_id: "",
        location_name: "",
        referralInformation: {
          referred: "Yes",
          firstName: "",
          lastName: "",
          primaryPhoneNumber: "",
          emailAddress: "",
        },
        personalDetails: {
          firstName: "",
          lastName: "",
          middleName: "",
          streetAddress: "",
          cityStateZipcode: "",
          locations: [],
          position: "",
          otherLocations: "",
          primaryPhoneNumber: "",
          secondaryPhoneNumber: "",
          emailAddress: "",
          is18YearsOfAge: "Yes",
          hasProofOfEligibilityForUS: "Yes",
        },
        workHistory: [
          {
            title: "",
            companyName: "",
            cityState: "",
            phone: "",
            startDate: "",
            endDate: "",
            currentPosition: "Yes",
            duties: "",
          },
        ],
        educationalHistory: {
          institutionName: "",
          cityState: "",
          graduate: "Yes",
          degreeType: "",
        },
        professionalReferences: [
          {
            firstNameLastName: "",
            primaryPhoneNumber: "",
            email: "",
            title: "",
            relationship: "",
          },
        ],
        uploadFileDetails: "",
        otherInformation: {
          howDidYouHearAboutUs: "",
          comments: "",
        },
      },
    };
  },
  mounted() {
    this.getJob();
    this.getAllBranches();
    localStorage.setItem("currentPage", "apply-jobs");
  },
  methods: {
    getAllBranches() {
      let apiUrl = process.env.VUE_APP_API_BASE + "auth/getAllBranches";
      axios
        .post(
          apiUrl,
          {},
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.branches = response.data.locations;
        });
    },
    getJob() {
      let jobId = this.$route.params.jobId;
      if (jobId > 0) {
        this.isLoading = true;
        let apiUrl = process.env.VUE_APP_API_BASE + "auth/getJob";
        axios
          .post(
            apiUrl,
            {
              filters: { id: this.$route.params.jobId },
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.job = response.data.jobInfo;
            this.isLoading = false;
            this.salesInformation.id = this.job.id;
          });
      }
    },

    addWorkHistory() {
      this.salesInformation.workHistory.push({
        title: "",
        companyName: "",
        cityState: "",
        phone: "",
        startDate: "",
        endDate: "",
        currentPosition: false,
        duties: "",
      });
    },
    removeWorkHistory(workHistoryIndex) {
      this.salesInformation.workHistory.splice(workHistoryIndex, 1);
    },
    addReference() {
      this.salesInformation.professionalReferences.push({
        firstNameLastName: "",
        primaryPhoneNumber: "",
        email: "",
        title: "",
        relationship: "",
      });
    },
    removeReference(referenceIndex) {
      this.salesInformation.professionalReferences.splice(referenceIndex, 1);
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      Array.from(files).forEach((file) => {
        this.createImage(file);
      });
    },
    createImage(file) {
      var reader = new FileReader();
      let fileArr = {}
      var vm = this;
      reader.onload = (e) => {
        fileArr = {'filename': file.name, 'file_content' :e.target.result}
        vm.files.push(fileArr);
      };
      reader.readAsDataURL(file);
    },

    validateForm() {
      let errorStatus = false;
      this.salesInformation.referralInformation["errors"] = {
        firstNameErrorStatus: false,
        lastNameErrorStatus: false,
        firstNameErrorMessage: "FirstName is a required field.",
        lastNameErrorMessage: "LastName is a required field.",
      };
      if (this.salesInformation.referralInformation.referred == "Yes") {
        if (!this.salesInformation.referralInformation.firstName) {
          errorStatus = true;
          this.salesInformation.referralInformation.errors.firstNameErrorStatus = true;
        }
        if (!this.salesInformation.referralInformation.lastName) {
          errorStatus = true;
          this.salesInformation.referralInformation.errors.lastNameErrorStatus = true;
        }
      }

      this.salesInformation.personalDetails["errors"] = {
        firstNameErrorStatus: false,
        lastNameErrorStatus: false,
        cityStateZipcodeErrorStatus: false,
        locationErrorStatus: false,
        otherLocationsErrorStatus: false,
        positionErrorStatus: false,
        primaryPhoneNumberErrorStatus: false,
        emailAddressErrorStatus: false,
        firstNameErrorMessage: "Firstname is a required field.",
        lastNameErrorMessage: "Lastname is a required field.",
        cityStateZipcodeErrorMessage: "City/State/Zipcode is a required field.",
        locationErrorMessage: "Location is a required field.",
        otherLocationsErrorMessage: "Other Locations is a required field.",
        positionErrorMessage: "Position is a required field.",
        primaryPhoneNumberErrorMessage:
          "Primary Phone Number is a required field.",
        emailAddressErrorMessage: "Email Address is a required field.",
      };
      if (!this.salesInformation.personalDetails.firstName) {
        errorStatus = true;
        this.salesInformation.personalDetails.errors.firstNameErrorStatus = true;
      }
      if (!this.salesInformation.personalDetails.lastName) {
        errorStatus = true;
        this.salesInformation.personalDetails.errors.lastNameErrorStatus = true;
      }
      if (!this.salesInformation.personalDetails.cityStateZipcode) {
        errorStatus = true;
        this.salesInformation.personalDetails.errors.cityStateZipcodeErrorStatus = true;
      }
      if (!this.salesInformation.personalDetails.primaryPhoneNumber) {
        errorStatus = true;
        this.salesInformation.personalDetails.errors.primaryPhoneNumberErrorStatus = true;
      }
      if (!this.salesInformation.personalDetails.emailAddress) {
        errorStatus = true;
        this.salesInformation.personalDetails.errors.emailAddressErrorStatus = true;
      }
      // if (
      //   !this.salesInformation.id &&
      //   this.salesInformation.personalDetails.locations.length < 1
      // ) {
      //   errorStatus = true;
      //   this.salesInformation.personalDetails.errors.locationErrorStatus = true;
      // }
      if (
        !this.salesInformation.id &&
        !this.salesInformation.personalDetails.position
      ) {
        errorStatus = true;
        this.salesInformation.personalDetails.errors.positionErrorStatus = true;
      }

      // if (this.salesInformation.personalDetails.locations.length < 1) {
      //   errorStatus = true;
      //   this.salesInformation.personalDetails.errors.locationErrorStatus = true;
      // }

      for (const workHistory of this.salesInformation.workHistory) {
        workHistory["errors"] = {
          companyNameErrorStatus: false,
          titleErrorStatus: false,
          cityStateErrorStatus: false,
          startDateErrorStatus: false,
          endDateErrorStatus: false,
          companyNameErrorMessage: "Company name is a required field.",
          titleErrorMessage: "Title is a required field.",
          cityStateErrorMessage: "City/State is a required field.",
          startDateErrorMessage: "Start Date is a required field.",
          endDateErrorMessage: "EndDate is a required field.",
        };
        if (!workHistory.companyName) {
          errorStatus = true;
          workHistory.errors.companyNameErrorStatus = true;
        }
        if (!workHistory.title) {
          errorStatus = true;
          workHistory.errors.titleErrorStatus = true;
        }
        if (!workHistory.cityState) {
          errorStatus = true;
          workHistory.errors.cityStateErrorStatus = true;
        }
        if (!workHistory.startDate) {
          errorStatus = true;
          workHistory.errors.startDateErrorStatus = true;
        }
        if (!workHistory.endDate) {
          errorStatus = true;
          workHistory.errors.endDateErrorStatus = true;
        }
      }

      if (!this.salesInformation.otherInformation.howDidYouHearAboutUs) {
        errorStatus = true;
        this.salesInformation.otherInformation["errors"] = {
          howDidYouHearAboutUsErrorStatus: true,
          howDidYouHearAboutUsErrorMessage: "It is a required field.",
        };
      }
      return errorStatus;
    },
    scrollToInvalidField() {
      // Without this setTimeout, an "undefined" error is thrown.
      setTimeout(() => {
        let el = this.$el.getElementsByClassName("error-text")[0];
        el.scrollIntoView();
      }, 0);
    },
    submitForm() {
      //   let formData = new FormData();
      //   formData.append("file", this.salesInformation.uploadFileDetails);
      delete this.salesInformation.referralInformation["errors"];
			delete this.salesInformation.otherInformation["errors"];
			delete this.salesInformation.personalDetails["errors"];
			this.salesInformation.workHistory.map((workHis) => {
				if (workHis["errors"]) {
          delete workHis["errors"];
				}
			});
      this.salesInformation.uploadFileDetails = this.files;
      if (this.job) {
        this.salesInformation.id = this.job.id;
        this.salesInformation.location_id = this.job.branch_id;
        this.salesInformation.location_name =
        this.job.city + ", " + this.job.state;
        this.salesInformation.position = this.job.title;
      } else {
        this.salesInformation.position =
        this.salesInformation.personalDetails.position;
      }
      if (!this.validateForm()) {
        delete this.salesInformation.referralInformation["errors"];
        delete this.salesInformation.otherInformation["errors"];
        delete this.salesInformation.personalDetails["errors"];
        this.salesInformation.workHistory.map((workHis) => {
          if (workHis["errors"]) {
            delete workHis["errors"];
          }
        });
        this.isLoading = true;
        axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/applyJob",
          this.salesInformation,
          {
            headers: {
              "Content-type": "application/json;multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error === true) {
            this.showFlashAlert(response.data.message, "error");
          } else {
            this.showFlashAlert(response.data.message, "success");
            this.$router.push('/careers-thank-you');
          }
          this.isLoading = false;
        });
      } else {
        this.scrollToInvalidField();
      }
    },
    selectLocations(locations, otherLocations) {
      this.salesInformation.personalDetails["errors"] = {
        locationMaxErrorStatus: false,
      };
      this.salesInformation.personalDetails["errors"] = {
        otherLocationsMaxErrorStatus: false,
      };
      if (locations.length > 3) {
        if (otherLocations) {
          this.salesInformation.personalDetails[
            "errors"
          ].otherLocationsMaxErrorStatus = true;
        } else {
          this.salesInformation.personalDetails[
            "errors"
          ].locationMaxErrorStatus = true;
        }
        locations.pop();
      }
    },
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
table {
  border-collapse: collapse;
}
.table td,
.table td a {
  color: #231f20;
}
.table td,
.table th {
  vertical-align: middle;
  font-size: 13px;
  padding: 0.5rem;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  /* border-top: 1px solid #dee2e6; */
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.job-board-con .panel-heading h4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.font-weight-bold {
  margin-top: 10px;
  font-weight: 700 !important;
}
.job-board-con .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.job-board-con .panel-default > .panel-heading {
  box-shadow: inherit;
  padding: 0;
}
.job-board-con .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.job-board-con .panel-body .form-group {
  width: 100%;
  float: left;
}
.form-group {
  margin-bottom: 1rem;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button {
  margin-right: 8px;
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn {
  text-transform: uppercase;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.job-board-con #apply_form div.required .control-label:before {
  font-size: 18px;
  width: 4px;
  display: inline-block;
}
#apply_form div.required .control-label:before {
  content: "* ";
  color: #f00;
  font-weight: bold;
}
.job-board-con .panel-body .form-control {
  font-size: 13px;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nice-select .current {
  overflow: hidden;
  height: 34px;
  display: block;
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  font-weight: normal;
  height: 42px;
  line-height: 28px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* white-space: nowrap; */
  width: auto;
  min-width: 80px;
  font-size: 14px;
}
.job-board-con .panel-body ul {
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}
.nice-select .list {
  background-color: #fff;
  box-shadow: 0 0 0 1px rgb(68 68 68 / 11%);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.red-asterick {
  color: red;
}
.error-text {
  color: red;
}
.pull-right {
  float: right;
  margin-top: 20px;
}
.text-danger {
  margin-top: 5px;
}
</style>