<template>
  <section id="blog-post-content" class="mt-4">
    <PageMetadata v-if="foundBlogPost" :pageInformation="blogPost"/>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <div class="row" v-if="blogPost['featured_image'] != null">
      <div class="col-md-12">
        <img :src="`${blogPost['featured_image']}`" class="w-100" />
      </div>
    </div>
    <h1 class="header text-center pt-80">{{ blogPost['html_title'] }}</h1>
    <div id="page_content" class="mt-10 content-wrapper" v-html="blogPost['body']"></div>
    <div class="content-wrapper">
      <p class="content-p mb-4">Published by {{ blogPost['author'] }} on {{ blogPost['publish_date_formatted'] }}</p>
      <p class="content-p mb-0">
        <template v-for="(tag, index) in tags" :key="index">
          <span v-if="index == 0">Tags:</span>
          <a :href="tag.slug" class="btn btn-theme mx-1 text-decoration-none">{{ tag.name }}</a>
          <!-- <span v-if="index < tags.length -1">, </span> -->
        </template>
      </p>
    </div>
    <div class="content-wrapper my-2 ">
      <div class="border-top border-bottom border-2 my-2 py-4">
        <a href="/blog"><i class="fa-solid fa-left-long me-1"></i> Back to Blog</a>
      </div>
      <div class="blog-comments">
        <template v-for="(comment, index) in comments" :key="index">
          <div class="comment">
            <div class="comment-from"><h4>{{ comment.name }}</h4></div>
            <div class="comment-date ps-1">{{ comment.created_at }}</div>
            <div class="comment-body"><p class="content-p">{{ comment.comment }}</p></div>
            <button class="comment-reply-to">Reply to <em>{{ comment.name }}</em></button>
            <div v-for="(reply, index) in comment.replies" :key="index">
              <div class="comment-reply">
                <div class="comment-from"><h4>{{ reply.name }}</h4></div>
                <div class="comment-date ps-1">{{ reply.created_at }}</div>
                <div class="comment-body"><p class="content-p">{{ reply.comment }}</p></div>
              </div>
            </div>
          </div>
        </template>

      </div>
      <div class="pb-3 mx-auto" id="hubspot-form">
      </div>
    </div>
  </section>
</template>
<script>
import { misc } from "@/mixins";
import axios from "axios";
import { mapMutations } from "vuex";
import { PageMetadata } from "@/components";

export default {
    name: "BlogPost",
    mixins: [misc],
    components: { PageMetadata },
    data() {
        return {
            loader: "bars",
            isLoading: false,
            fullPage: true,
            blog_post_slug: this.$route.params.blog_post_slug,
            blogPost: {
                details: {}
            },
            tags: [],
            comments: [],
            portalId: '',
            commentsFormId: '',
            foundBlogPost: false,
        };
    },
    created() {
      this.blogPostDetails();
    },
    mounted() {
      this.emitter.on("showLoader", () => {
        this.showLoader();
      });
    },
    methods: {
      ...mapMutations(["updateDynamicBreadcrumb"]),
      showLoader() {
          this.isLoading = true;
      },
      blogPostDetails() {
        this.showLoader();
        try {
          axios
            .post(process.env.VUE_APP_API_BASE + "auth/getBlogPost", {
              slug: this.blog_post_slug,
            }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (!response.data.error) {
              this.blogPost = response.data.content;
              this.foundBlogPost = true;
              this.tags = this.blogPost.tags;
              this.comments = this.blogPost.comments;
              const crumb = [
                {
                  routeName: "Home",
                  text: '<i class="fa-solid fa-house-chimney"></i>',
                },
                {
                  routeName: "Blog",
                  text: 'Blog',
                },
              ];
              crumb.push({
                route: this.$route.fullPath,
                text: this.blogPost['html_title'],
              });
              this.updateDynamicBreadcrumb(crumb);
              this.isLoading = false;
            }
            else {
              this.showFlashAlert(response.data.message, "error");
            }
            let site_settings = JSON.parse(localStorage.getItem('site_settings'));
            this.portalId = site_settings.hub_spot_portal_id;
            this.commentsFormId = site_settings.hub_spot_blog_post_form_id;
            if (this.portalId && this.commentsFormId) {
              const script = document.createElement("script");
              script.src = "//js.hsforms.net/forms/v2.js";
              document.body.appendChild(script);
              script.addEventListener("load", () => {
                if (window.hbspt) {
                  window.hbspt.forms.create({
                    portalId: this.portalId,
                    formId: this.commentsFormId,
                    target: "#hubspot-form",
                  });
                }
              });
            }
          });
        }
        catch {
          this.isLoading = false;
        }
      },
    },
};
</script>
<style scoped>
  .comment-reply-title{
    text-transform: initial;
    letter-spacing: 0px;
  }
  form .hs-richtext, form .hs-richtext p {
    font-size: .875rem !important;
    margin: 0 0 1.4rem !important;
  }
  .hs-input {
    border-color: #e4e6e8 !important;
    color: #231f20 !important;
    box-sizing: border-box !important;
    padding: 0 15px !important;
    min-height: 27px !important;
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0 !important;
    background-color: #f5f8fa !important;
    display: inline-block !important;
    height: 40px !important;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    border: 1px solid #cbd6e2 !important;
    outline: 0 !important;
  }
  form .inputs-list {
    margin: 0 0 5px !important;
    width: 100% !important;
    padding-left: 5px !important;
  }
  form .inputs-list>li {
    display: block !important;
    width: 100% !important;
    padding: 0 !important;
  }
  form label {
    font-family: Raleway !important;
    font-size: 14px !important;
    display: block !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding-top: 0 !important;
    margin-bottom: 4px !important;
  }
  .hs-error-msg, .hs-error-msgs li label {
    color: #f2545b !important;
  }
  .blog-comments .hs-submit {
    text-align: center !important;
  }
  .hs_submit hs-submit {
    text-align: center !important;
  }
  form input[type=submit]:hover, form .hs-button:hover, form .hs-button:focus {
    background-color: rgba(244,128,98,1.0) !important;
    border-color: rgba(255,122,89,1.0) !important;
    color: rgba(255,255,255,1.0) !important;
  }
  input.hs-input.error:focus, 
  .hs-form div.field.error input:focus, 
  .hs-form div.field.error textarea:focus, 
  .hs-form div.field.error .chzn-choices:focus, 
  textarea.hs-input.error:focus, 
  select.hs-input.error:focus {
    box-shadow: none !important;
  }
  .hs-form .hs-button:hover:not(.inactive), 
  .hs-form .hs-button:focus:not(.inactive), 
  .hs-form .hs-button.hovered:not(.inactive) {
    box-shadow: none !important;
  }
  input.hs-input:focus, textarea.hs-input:focus {
    box-shadow: none !important;
  }
  form input[type=submit], form .hs-button {
    background-color: rgba(244,128,98,1.0) !important;
    border: 1px solid #ff7a59 !important;
    border-radius: 0px !important;
    color: #fff !important;
    padding: 12px 24px !important;
  }
  form .hs-button, form input[type=submit] {
    white-space: normal !important;
    font-family: Raleway !important;
    display: inline-block !important;
    font-weight: 700 !important;
    line-height: 12px !important;
    text-align: center !important;
    transition: all .15s linear !important;
    font-size: 14px !important;
    cursor: pointer !important;
    margin: 20px 0 !important;
  }
  .grecaptcha-badge {
    margin: 0 auto !important;
  }
  .base-hs-button-styles {
    text-align: center !important;
  }
  .hs-submit > .actions {
    text-align: center !important;
  }
  .hs-form .hs-button {
    text-shadow: none !important;
    box-shadow: none !important;
  }
</style>
